<template>
    <div class="page-wrapper">
        <PageHeader />
        <main class="main">
            <div class="page-body">
                <div class="container">
                    <div class="page-inner">
                        <ButtonBack />
                        <div class="minting">
                            <div class="minting--token-mint token-mint">
                                <div class="token-mint--inner">
                                    <div class="token-mint--img-wrap">
                                        <img class="token-mint--img" src="@/assets/images/minting-token.png"
                                            alt="minting token">
                                    </div>
                                </div>
                            </div>
                            <h1 class="title-h1">Mint you Jellyz NFT. {{ mintedTokens || '?' }} from {{ maxSupply }} are already
                                minted</h1>
                            <div class="minting--actions">
                                <div class="minting--quantity-select quantity-select">
                                    <ButtonBase @click="decrement">
                                        <img src="@/assets/images/svg/arrow-left1.svg" alt="arrow left">
                                    </ButtonBase>
                                    <span class="quantity-select--value">{{ mintCount }}</span>
                                    <ButtonBase @click="increment">
                                        <img src="@/assets/images/svg/arrow-right.svg" alt="arrow right">
                                    </ButtonBase>
                                </div>
                                <ButtonBase @click="runMinting" :disabledBtn="!isCanMint || isMinting">
                                    <span>Mint</span>
                                    <span class="button-base--coins">{{ mintSum }}</span>
                                    <span>ETH</span>
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import NProgress from 'nprogress'
import router from "@/router";

export default {
    data() {
        return {
            mintCount: 1,
            isMinting: false
        };
    },
    computed: {
        ...mapState(['batchLimit', 'isCanMint', 'maxSupply', 'mintedTokens', 'mintPrice', 'tokens']),
        mintSum() {
            return this.mintPrice * this.mintCount
        },
        mintTokensDifference() {
            return this.maxSupply - this.mintedTokens
        },
    },
    created() {
        this.getMintedAmount()
    },
    methods: {
        ...mapActions(['checkMintAvailability', 'getMintedAmount', 'loadBalance', 'loadTokens', 'mintTokens']),
        increment() {
            if (this.mintCount < this.batchLimit && this.mintCount < (this.maxSupply - this.mintedTokens)) { this.mintCount++ }
        },
        decrement() {
            if (this.mintCount > 1) { this.mintCount-- }
        },
        runMinting() {
            if (this.isCanMint) {
                NProgress.start()

                this.isMinting = true

                this.mintTokens(this.mintCount).then(() => {
                    this.checkMintAvailability()
                    this.loadBalance()
                    this.getMintedAmount()
                    this.loadTokens()
                    console.log('minted tokens are loaded')
                }).catch(err => {
                    console.log('Minting error: ', err)
                    router.push({ name: 'minting-error' })
                })
                    .finally(() => {
                        this.isMinting = false
                        NProgress.done()
                    })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.minting {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--token-mint {
        width: 100%;
        max-width: 270px;
    }

    .title-h1 {
        max-width: 9.33em;
        text-align: center;
        margin-top: 0.48em;
    }

    &--actions {
        min-width: 270px;
        margin-top: 1.78em;

        &>.button-base {
            margin-top: 1.78em;
        }

        .button-base--coins {
            margin: 0 4px 0 14px;
        }

    }
}

.token-mint {
    &--inner {
        position: relative;
        padding-top: 100%;

    }

    &--img-wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @include center;
    }

    &--img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

}

.quantity-select {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 17px;

    .button-base {
        width: 100%;
        max-width: 5.22em;
        padding: 10px;
    }

    &--value {
        @include center;
        min-width: 46px;
        font-size: 35px;
        line-height: 1.1;
        letter-spacing: 0.01em;
        font-family: 'Karantina';
        font-weight: 700;
    }
}

// media queries
@include w($sm) {
    .minting {
        &--token-mint {
            width: 53%;
        }

        &--actions {
            margin-top: 2.78em;

            &>.button-base {
                margin-top: 2.78em;
            }
        }
    }
}
</style>