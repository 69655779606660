<template>
    <div class="page-wrapper">
        <PageHeader />
        <main class="main">
            <div class="page-body">
                <div class="container">
                    <div class="page-inner">
                        <ButtonBack />
                        <div class="mint-error--body">
                            <div class="mint-error--block">
                                <h1 class="title-h1">Could not mint tokens. Try to mint later</h1>
                                <router-link :to="{ name: 'gallery' }" class="button-base">Back to gallery</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
.mint-error {
    &--body {
        padding-top: 30px;
    }

    &--block {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.title-h1 {
    text-align: center;
    max-width: 8em;
}

.button-base {
    max-width: 290px;
    margin: 40px 0 0;
}

// media queries
@include w($sm) {
    .main {
        display: flex;
    }

    .page-body {
        width: 100%;
    }

    .container {
        height: 100%;
    }

    .page-inner {
        height: 100%;
        padding-bottom: 130px;
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }

    .mint-error {
        &--body {
            padding: 0;
            margin: auto 0;
        }
    }

    .button-base {
        margin: 24px 0 0;
    }
}
</style>