<template>
    <div class="login">
        <div class="login-inner">
            <div class="login-block">
                <div class="logo">
                    <img src="@/assets/images/svg/logo.svg" alt="logo" />
                </div>
                <div class="login-main">
                    <h1 class="title-h1">Select a wallet to get started</h1>
                    <div class="login-btns">
                        <LoginButton @click="metamaskConnect" :disabledBtn="isLoggingIn"
                            :imgSrc="require(`@/assets/images/wallet_icons/metamask.svg`)">
                            Metamask
                        </LoginButton>
                        <LoginButton @click="walletConnect" :disabledBtn="isLoggingIn"
                            :imgSrc="require(`@/assets/images/wallet_icons/connect.svg`)">Wallet Connect
                        </LoginButton>
                        <LoginButton @click="coinBaseWalletConnect" :disabledBtn="isLoggingIn"
                            :imgSrc="require(`@/assets/images/wallet_icons/coinbase.svg`)">
                            Coinbase Wallet
                        </LoginButton>
                    </div>
                </div>
            </div>
            <div class="login-poster">
                <img src="@/assets/images/candyman_kitchen.jpg" alt="candyman kitchen" />
            </div>
        </div>
    </div>
    <Modal v-model="isNoHaveAppError" :close="closeModal">
        <div class="modal modal--error">
            <div class="modal--bg"></div>
            <div class="modal--inner">
                <button @click="closeModal" class="modal--close">
                    <img src="@/assets/images/svg/close.svg" alt="close">
                </button>
                <p class="title-h1">{{ isNoHaveAppErrorMessage }}</p>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    methods: {
        ...mapActions(['coinBaseWalletConnect', 'getAccount', 'metamaskConnect', 'walletConnect']),
        ...mapMutations(['SET_IS_NO_HAVE_APP_ERROR']),
        closeModal() {
            this.SET_IS_NO_HAVE_APP_ERROR(false)
        }
    },
    computed: {
        ...mapState(['accountAddress', 'isLoggingIn', 'isNoHaveAppError', 'isNoHaveAppErrorMessage', 'tokens'])
    }
}
</script>

<style lang="scss" scoped>
.login {
    display: flex;

    &-inner {
        display: flex;
        width: 100%;
    }

    &-block {
        width: 50%;
        flex-shrink: 0;
        padding: 2.22rem 0 2.22rem 8.6%;
        min-height: 100vh;
    }

    &-poster {
        width: 50%;
        flex-shrink: 0;
        min-height: 100vh;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14.2vh;
        max-width: 17.45rem;
        padding-top: 2rem;
        padding-bottom: 2rem;

        .title-h1 {
            margin-bottom: 0.8em;
            text-align: center;
        }
    }

    &-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.67rem;
        width: 100%;
        max-width: 16.1rem;
    }
}


// modal error
.modal {
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 650px;
    min-height: 370px;
    position: relative;

    &--bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 30px solid $black;
        border-image: url(~@/assets/images/mob-menu-bg.png) 30;
        background-color: $black;
    }

    &--inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        padding: 3.33em 1.33em;
    }

    &--close {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

// media queries
@include w(1800) {
    .login {
        &-block {
            display: flex;
            flex-direction: column;
        }

        &-main {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

@include w($tablet) {
    .login {
        &-block {
            align-items: center;
            width: 100%;
            padding-right: 8.6%;
            padding-bottom: 4rem;
        }

        &-poster {
            display: none;
        }

        &-main {
            max-width: 16.11rem;
            margin: auto;
        }
    }

    .logo {
        align-self: flex-start;

        img {
            width: 159px;
        }
    }
}

@include w($sm) {
    .login {
        &-block {
            padding: $sm-p;
        }
    }

    .logo {
        align-self: initial;
    }

    .modal {
        min-height: 290px;

        &--inner {
            padding-left: 0.89rem;
            padding-right: 0.89rem;
        }
    }
}

h3 {
    margin: 40px 0 0;
}

.button {
    background-color: #1c82ff;
    border: none;
    color: #ffffff;
    font-family: "Sora";
    border-radius: 3rem;
    padding: 1rem;
    font-weight: 600;
    font-size: 1rem;
    margin: 1rem 1rem 1rem auto;
    width: 100%;
    text-align: center;
}

.disconnect__button {
    background-color: red;
    border: none;
    color: #ffffff;
    font-family: "Sora";
    border-radius: 3rem;
    padding: 1rem 1.3rem;
    font-weight: 600;
    font-size: 1rem;
    margin: 8rem 1rem 1rem auto;
    width: 100%;
    text-align: center;
}
</style>
