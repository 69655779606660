<template>
    <div class="page-wrapper">
        <PageHeader />
        <main class="main">
            <div class="page-body">
                <div class="container">
                    <div class="page-inner">
                        <ButtonBack />
                        <div class="breed">
                            <h1 class="title-h1">Pick two characters to breed a new one</h1>
                            <div class="breed--cards">
                                <div v-for="(token, index) in tokensToBreed" :key="index" class="breed--card">
                                    <div class="breed--card-wrap">
                                        <router-link :to="{ name: 'gallery' }" v-if="token === null"
                                            class="breed--card-select"><img class="breed--select-img"
                                                src="@/assets/images/breed-choosing.png" alt="token select">
                                        </router-link>
                                        <div v-else class="token--selected">
                                            <button @click="removeBreedToken(index)" class="token--remove-btn">
                                                <img src="@/assets/images/svg/close.svg" alt="">
                                            </button>
                                            <img :src=token.imageUri alt="token image" class="token--img">
                                            <div class="token--number">#{{ token.id }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="breed--conditions">
                                <p class="title-h1">Both characters must have:</p>
                                <ul class="breed--conditions-list">
                                    <li>
                                        <img class="breed--conditions-img"
                                            src="@/assets/images/svg/shield-transp-small.svg" alt="">
                                        <span class="breed--conditions-text">Level 1 or higher</span>
                                    </li>
                                    <li>
                                        <img class="breed--conditions-img" src="@/assets/images/svg/sports-cup.svg"
                                            alt="">
                                        <span class="breed--conditions-text">The stage is Adult</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="breed--actions">
                                <p v-if="!isEnoughCoins" class="breed--money-warning-msg">You don’t have enough money
                                </p>
                                <ButtonBase :disabledBtn="!isCanBreed" @click="showConfirmModal">
                                    <span>Breed</span>
                                    <span class="button-base--coins">{{ breedingPrice }}</span>
                                    <img src="@/assets/images/svg/small-coin.svg" alt="coin" />
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <Modal v-model="isShowConfirm" :close="closeConfirmModal">
        <div class="modal">
            <div class="modal--bg"></div>
            <div class="modal--inner">
                <button @click="closeConfirmModal" class="modal--close">
                    <img src="@/assets/images/svg/close.svg" alt="close">
                </button>
                <p class="modal--text">You are about to breed token #{{ tokensToBreed[0].id }} and token #{{
                        tokensToBreed[1].id
                }}. You are guaranteed to get a
                    baby
                    <br>
                    <br>
                    Jellyz NFT, but there is a chance of getting your token #{{ tokensToBreed[0].id }} or #{{
                            tokensToBreed[1].id
                    }} burned.
                    <br>
                    <br>
                    Please click confirm to proceed. If you want to read more about breeding mechanics,
                    <br>
                    <br>
                    please check out our <a class="link" href="#">whitepaper</a>.
                </p>
                <div class="modal--actions">
                    <ButtonBase @click="runBreeding">
                        <span>Breed</span>
                        <span class="button-base--coins">{{ breedingPrice }}</span>
                        <img src="@/assets/images/svg/small-coin.svg" alt="coin" />
                    </ButtonBase>
                </div>
            </div>
        </div>
    </Modal>
    <Modal v-model="isShowCongratulation" :close="closeCongratulationModal">
        <div class="modal congratulation-modal">
            <div class="modal--bg"></div>
            <div class="modal--inner">
                <button @click="closeCongratulationModal" class="modal--close">
                    <img src="@/assets/images/svg/close.svg" alt="close">
                </button>
                <img src="@/assets/images/svg/check-in-circle.svg" alt="">
                <p class="title-h1">Congratulations. Your transaction is posted. Soon, you’ll receive your baby NFTo</p>
            </div>
        </div>
    </Modal>
    <Modal v-model="isShowBreedingLoading" :close="closeBreedingLoadingModal" :options="breedingLoadingModalOptions">
        <div class="loading">
            <div class="loading--inner">
                <span class="loading--text">Breeding</span>
                <img src="@/assets/images/loading.gif" alt="">
            </div>
        </div>
    </Modal>
    <Modal v-model="isShowBreedingError" :close="closeBreedingErrorModal">
        <div class="modal modal--error">
            <div class="modal--bg"></div>
            <div class="modal--inner">
                <button @click="closeBreedingErrorModal" class="modal--close">
                    <img src="@/assets/images/svg/close.svg" alt="close">
                </button>
                <p class="title-h1">Breeding error</p>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    data() {
        return {
            isBreeding: false,
            isShowConfirm: false,
            isShowCongratulation: false,
            isShowBreedingLoading: false,
            isShowBreedingError: false,
            breedingLoadingModalOptions: {
                closeClickDimmed: false,
                closeKeyCode: false,
            },
        }
    },
    computed: {
        ...mapState(['tokensToBreed', 'breedingPrice', 'balance']),
        isBothTokensSelected() {
            if (this.tokensToBreed[0] !== null && this.tokensToBreed[1] !== null) {
                return true
            }
            return false
        },
        isCanBreed() {
            if (this.isEnoughCoins && this.isMeetBreedConditions && !this.isBreeding) {
                return true
            }
            return false
        },
        isEnoughCoins() {
            if (this.balance >= this.breedingPrice) {
                return true
            }
            return false
        },
        isMeetBreedConditions() {
            let result = false
            let count = 0
            for (let i = 0; i < this.tokensToBreed.length; i++) {
                if (this.tokensToBreed[i] === null) {
                    return false
                }
                if (this.tokensToBreed[i].stage == 2 && this.tokensToBreed[i].breedingCooldown == 0) {
                    count++
                }
            }
            if (count == 2) {
                result = true
            }
            return result
        },
        tokenIds() {
            if (this.isBothTokensSelected) {
                return [Number(this.tokensToBreed[0].id), Number(this.tokensToBreed[1].id)]
            }
            return []
        }

    },
    methods: {
        ...mapActions(['breedTokens', 'loadBalance']),
        ...mapMutations(['REMOVE_TOKEN_FROM_BREED']),
        closeBreedingErrorModal() {
            this.isShowBreedingError = false
        },
        closeBreedingLoadingModal() {
            this.isShowBreedingLoading = false
        },
        closeConfirmModal() {
            this.isShowConfirm = false;
        },
        closeCongratulationModal() {
            this.isShowCongratulation = false;
        },
        removeBreedToken(pos) {
            if (this.tokensToBreed[pos] !== null) {
                this.REMOVE_TOKEN_FROM_BREED(pos)
            }
        },
        runBreeding() {
            if (this.isCanBreed) {
                this.isBreeding = true
                this.closeConfirmModal();

                this.showBreedingLoadingModal()

                this.breedTokens(this.tokenIds).then(() => {
                    this.runTimeoutFunctions()
                }).catch(error => {
                    console.log('breeding error', error)
                    this.closeBreedingLoadingModal()
                    this.showBreedingErrorModal()
                }).finally(()=>this.isBreeding = false)
            }
        },
        runTimeoutFunctions() {
            this.closeBreedingLoadingModal()
            this.showCongratulationModal()
            this.REMOVE_TOKEN_FROM_BREED(0)
            this.REMOVE_TOKEN_FROM_BREED(1)
            this.loadBalance()
        },
        showBreedingErrorModal() {
            this.isShowBreedingError = true
        },
        showBreedingLoadingModal() {
            this.isShowBreedingLoading = true
        },
        showConfirmModal() {
            if (this.isCanBreed) { this.isShowConfirm = true; }
        },
        showCongratulationModal() {
            this.isShowCongratulation = true;
        },
    }
}
</script>

<style lang="scss" scoped>
.breed {
    display: flex;
    flex-direction: column;
    align-items: center;

    &>.title-h1 {
        max-width: 7.3em;
        text-align: center;
    }

    &--cards {
        display: flex;
        width: 100%;
        justify-content: center;
        column-gap: 1.34em;
        margin-top: 2.23em;
        margin-bottom: 2.23em;
    }

    &--card {
        width: 100%;
        max-width: 270px;
    }

    &--card-wrap {
        width: 100%;
        padding-top: 100%;
        position: relative;
    }

    &--card-select {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    &--select-img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    &--conditions {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--conditions-list {
        display: flex;
        flex-direction: column;
        row-gap: 0.89em;
        margin-top: 1.33em;

        li {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }

    &--conditions-text {
        font-size: 0.944rem;
        line-height: 1.45;
    }

    &--actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.78em;

        .button-base {
            width: 100%;
            max-width: 290px;

            &--coins {
                margin: 0 4px 0 14px;
            }
        }
    }

    &--money-warning-msg {
        font-size: 0.78rem;
        line-height: 1.45;
        text-align: center;
        margin-bottom: 10px;
    }
}

.token {
    &--selected {
        @include center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(~@/assets/images/token-card-bg.png);
        background-repeat: no-repeat;
        background-size: cover;
    }

    &--remove-btn {
        position: absolute;
        top: 8px;
        right: 8px;
        @include center;
    }

    &--img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }

    &--number {
        position: absolute;
        bottom: 16px;
        left: 16px;
        font-size: 0.9444rem;
        line-height: 1.45;
    }
}

// confirm modal
.modal {
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 650px;
    min-height: 370px;
    position: relative;

    &--bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 30px solid $black;
        border-image: url(~@/assets/images/mob-menu-bg.png) 30;
        background-color: $black;
    }

    &--inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        padding: 3.33em 1.33em;
    }

    &--close {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    &--text {
        font-size: 0.944rem;
        line-height: 1.45;
        text-align: initial;
    }

    &--actions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 1.78em;

        .button-base {
            width: 100%;
            max-width: 260px;
        }
    }

    .button-base--coins {
        margin: 0 4px 0 14px;
    }

    .link {
        color: $purple-light;
    }

    &.congratulation-modal {
        .modal {
            &--inner {
                padding: 60px 44px 80px;

                .title-h1 {
                    margin-top: 0.5em;
                }
            }
        }
    }
}

.loading {
    background-color: #9F6EE8;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%;
        background-color: $purple;
        animation: loading 30s linear forwards;
    }

    &--inner {
        @include center;
        column-gap: 14px;
        width: 260px;
        padding: 1em;
        position: relative;
    }

    img {
        width: 26px;
    }
}

@keyframes loading {
    from {
        width: 0;
    }

    to {
        width: 97%;
    }
}

// media queries
@include w($sm) {

    .breed {

        &--cards {
            column-gap: 0.56em;
            margin: 1.11em 0;
        }

        &--conditions-list {
            margin-top: 0.83em;
            row-gap: 0.83em;
        }

        &--conditions-text {
            font-size: 0.78rem;
        }

        &--actions {
            margin-top: 2.22em;
        }

        &--money-warning-msg {
            margin-bottom: 3px;
        }
    }

    .modal {
        min-height: 290px;

        &--inner {
            padding-left: 0.89rem;
            padding-right: 0.89rem;
        }

        &--text {
            font-size: 0.78rem;
        }

        &--actions {
            margin-top: 1.33em;
        }

        .button-base--coins {
            margin-left: 6px;
        }

        &.congratulation-modal {
            .modal {
                &--inner {
                    padding: 60px 15px;

                    .title-h1 {
                        margin-top: 0.6em;
                    }
                }
            }
        }
    }
}

@include w(550) {
    .token {
        &--img {
            border-radius: 4px;
        }

        &--remove-btn {
            top: 5px;
            right: 5px;
        }

        &--number {
            font-size: 0.78rem;
            bottom: 4px;
            left: 6px;
        }
    }
}
</style>