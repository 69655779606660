<template>
    <div class="tokens--list">
        <TokenCard v-for="(token, i) in unStakedTokens" :key="i" :tokenData=token />
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TokenCard from "../components/TokenCard.vue";
export default {
    components: { TokenCard },
    computed: {
        ...mapState(['tokens']),
        ...mapGetters(['unStakedTokens']),
    }
}
</script>

<style lang="scss" scoped>
.tokens {
    &--list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        flex-wrap: wrap;
        gap: 45px 22px;
    }
}

// media queries
@include w(1400) {
    .tokens {
        &--list {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@include w(1130) {
    .tokens {
        &--list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@include w(870) {
    .tokens {
        &--list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include w(550) {
    .tokens {
        &--list {
            gap: 22px 10px;
        }
    }
}
</style>