<template>
    <router-link :to="{ name: 'token', params: { id: tokenData.id }}" class="token-card">
        <div class="token-card--wrap">
            <div class="token-card--img-wrap">
                <img :src=tokenData.imageUri alt="token">
            </div>
            <div class="token-card--inner">
                <div class="token-card--top">
                    <span class="token-card--number">#{{ tokenData.id }}</span>
                    <div class="token-card--level">
                        <img src="@/assets/images/svg/shield.svg" alt="shield">
                        <span>{{ tokenData.level }}</span>
                    </div>
                </div>
                <div class="token-card--bottom">
                    <span v-if="tokenData.dailyRate > 0" class="token-card--cost">{{ tokenData.dailyRate }}<img
                            src="@/assets/images/svg/coin.svg">/day</span>
                    <span class="token-card--breed">Breed: {{ tokenData.breedingTorpor }}/8</span>
                </div>
            </div>
            <div class="token-card--tag"
                :class="[{ 'token-card--tag-green': tokenData.rarity == 0, 'token-card--tag-blue': tokenData.rarity == 1, 'token-card--tag-orange': tokenData.rarity == 2 }]">
                {{ tokenData.rarity == 0 ? "Common" : tokenData.rarity == 1 ? "Rare" : "Epic" }}</div>
        </div></router-link>
</template>

<script>
export default {
    props: {
        tokenData: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" >
.token-card {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 6px;
    background-image: url(~@/assets/images/token-card-bg.png);
    background-repeat: no-repeat;
    background-size: cover;

    &--wrap {
        width: 100%;
        padding-top: 100%;
        position: relative;
    }

    &--img-wrap {
        @include center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 6px;
        overflow: hidden;

        img {
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &--inner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 12px 12px 27px;
    }

    &--top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &--number {
        font-size: 0.9444rem;
        line-height: 1.45;
    }

    &--level {
        @include center;
        position: relative;

        span {
            position: absolute;
            font-size: 0.78rem;
            line-height: 1.45
        }
    }

    &--bottom {
        display: flex;
        flex-direction: column
    }

    &--cost,
    &--breed {
        font-size: 0.944rem;
        line-height: 1.45
    }

    &--cost {
        display: flex;
        margin-bottom: 4px;

        img {
            width: 11px;
            margin: 0 2px;
        }
    }

    &--tag {
        @include center;
        width: 110px;
        height: 40px;
        padding: 7px;
        font-size: 0.9444rem;
        line-height: 1.45;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        border-radius: 2px;

        &-green {
            background-color: $green;
        }

        &-blue {
            background-color: $blue1;
        }

        &-orange {
            background-color: $orange;
        }
    }
}

// media queries
@include w(550) {
    .token-card {

        &--inner {
            padding: 3px 6px 16px;
        }

        &--number {
            font-size: 0.67rem;
            font-weight: 500;
            letter-spacing: 0.01em;
        }

        &--level {
            img {
                width: 28px;
            }

            span {
                font-size: 0.67rem;
                font-weight: 500;
                letter-spacing: 0.01em;
            }
        }

        &--cost,
        &--breed {
            font-size: 0.67rem;
            font-weight: 500;
            letter-spacing: 0.01em;
        }

        &--cost {
            margin-bottom: 2px;
        }

        &--tag {
            font-size: 0.67rem;
            letter-spacing: 0.01em;
            width: 80px;
            height: 24px;
        }
    }
}
</style>