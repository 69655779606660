<template>
    <button class="button-base" :class="[{disabled: disabledBtn}, className]" :disabled="disabledBtn">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: ['className', 'disabledBtn'],
    name: 'ButtonBase',
    methods: {
    },
}
</script>

<style lang="scss" >
.button-base {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 4px;
    background-color: $purple;
    color: $white;
    padding: 1em;
    width: 100%;
    cursor: pointer;
    transition: $anim-03;

    @include offHoverOnDevices {
        &:hover {
            background-color: rgba($purple, 0.7);
        }
    }
    &.disabled{
        background-color: $grey-dark;
        pointer-events: none;
        cursor: default;
        img, svg{
            opacity: 0.7;
        }
    }
}

</style>