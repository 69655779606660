<template>
    <div class="page-wrapper">
        <PageHeader />
        <main class="main">
            <div class="page-body">
                <div class="container">
                    <div class="page-inner">
                        <ButtonBack />
                        <div class="title-h1">Tokens in minting</div>
                        <div class="minting-tokens">
                            <MintingToken v-for="(token, i) in mintingTokens" :key="i" :tokenData="token" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapState } from "vuex";
import MintingToken from '../components/MintingToken.vue';

export default {
    components: { MintingToken },
    data() {
        return {
            isXsMobile: false,
            isTraitsShowed: false
        }
    },
    computed: {
        ...mapState(['mintingTokens']),
    },
    methods: {
    },
    created() {
    },
    beforeUnMount() {
    },
}
</script>

<style lang="scss" scoped>
.minting-tokens {
    margin-top: 1.78rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 40px 22.5px;
}


// media queries
@include w(1400) {
    .minting-tokens {
        grid-template-columns: repeat(4, 1fr);
    }
}

@include w(1150) {
    .minting-tokens {
        grid-template-columns: repeat(3, 1fr);
    }
}

@include w(900) {
    .minting-tokens {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include w($sm) {
    .minting-tokens {
        margin-top: 0.78em;
    }
}

@include w(600) {
    .minting-tokens {
        grid-template-columns: 1fr;
    }
}
</style>