<template>
    <div href="#" class="mint-token">
        <div class="mint-token--img-wrap">
            <div class="mint-token--bg">
                <img class="mint-token--img" src="@/assets/images/mint-token.png" alt="minting token">
            </div>
        </div>
        <div class="mint-token--details">
            <div class="mint-token--end-time">
                Will end in 00:44:55
            </div>
            <ButtonBase @click="mintTokenTestClick">
                <span>Skip minting</span>
                <span class="mint-token--coins">{{ tokenData.coins }}</span>
                <img src="@/assets/images/svg/small-coin.svg" alt="coin">
            </ButtonBase>
        </div>
    </div>
</template>

<script>
import ButtonBase from './UI/ButtonBase.vue';
export default {
    props: {
        tokenData: {
            type: Object,
            required: true
        }
    },
    components: { ButtonBase },
    methods: {
        mintTokenTestClick() {
            console.log('Minting token ButtonBase click');
        },
    }
}
</script>

<style lang="scss" >
.mint-token {
    &--img-wrap {
        position: relative;
        padding-top: 100%;
    }

    &--bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 28px solid $black;
        border-image: url(~@/assets/images/mint-token-bg.png) 28;
        background-color: $black;
    }

    &--img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
    }

    &--end-time {
        text-align: center;
        padding: 0.89em 0;
        font-size: 0.9444rem;
        line-height: 1.45;
    }

    &--coins {
        margin: 0 4px 0 14px;
    }
}

// media queries
@include w($sm) {
    .mint-token {
        &--end-time {
            padding: 0.59em 0;
        }
    }
}

@include w(600) {
    .mint-token {
        &--bg {
            border-width: 19px;
        }
    }
}
</style>