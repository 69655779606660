import store from "@/store"
import router from "@/router"
import { JellyzService } from "@/jellyzDappLib/jellyzservice"
import Web3 from "web3"
import {
  getCoinbaseWalletProvider,
  getMetaMaskProvider,
  getWalletConnectProvider,
} from "@/services/providers"

// TODO use appropriate web3 providerJellyz based on what user logged in with
let providerType = localStorage.getItem('providerType') || null
export let providerJellyz = null

console.log("providerType is - ", providerType)

if (providerType == "metamask") {
  providerJellyz = getMetaMaskProvider()
  providerJellyz.on("accountsChanged", providerOnAccountChange)

  console.log("providerJellyz set 'accountsChanged' eventListener")
  console.log("providerJellyz is - ", providerJellyz)
} else if (providerType == "coinbasewallet") {
  providerJellyz = getCoinbaseWalletProvider()
  providerJellyz.on("accountsChanged", providerOnAccountChange)

  console.log("providerJellyz set 'accountsChanged' eventListener")
  console.log("providerJellyz is - ", providerJellyz)
} else if (providerType == "walletconnect") {
  ;(async () => {
    providerJellyz = getWalletConnectProvider()
    await providerJellyz.enable()
    providerJellyz.on("accountsChanged", providerOnAccountChange)
  })()

  console.log("providerJellyz set 'accountsChanged' eventListener")
  console.log("providerType is - ", providerType)
  console.log("providerJellyz is - ", providerJellyz)
}

export let web3 = providerJellyz ? new Web3(providerJellyz) : null
console.log("web3.currentProvider is - ", web3 ? web3.currentProvider : null)

export let JellyzObj = new JellyzService(new Web3(providerJellyz))

export async function providerOnAccountChange(accounts) {
    if (accounts.length > 0) {
      console.log("accountsChanged event, changing account on: ", accounts[0])
      await store.dispatch("reloadAccountData", accounts[0])
      router.push({ name: "gallery" })
    }
  }