<template>
    <div class="back-block">
        <button class="button-back" :class="className" @click="$router.go(-1)">
            <SvgIcon icon="arrow-left" />
            <span>Back</span>
        </button>
    </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue';
export default {
    props: ["className"],
    name: "ButtonBack",
    methods: {},
    components: { SvgIcon }
}
</script>

<style lang="scss" scoped>
.back-block {
    padding-bottom: 32px;
}

.button-back {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.45;
    color: $white;
    cursor: pointer;
    transition: $anim-03;

    @include offHoverOnDevices {
        &:hover {
            color: $purple;
        }
    }

    span {
        margin-left: 6px;
    }
}

.icon-wrap {
    width: 20px;
    height: 20px;
}

// media queries
@include w($sm) {
    .back-block {
        padding-bottom: 14px;
    }
}
</style>