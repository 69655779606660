import CoinbaseWalletSDK from "@coinbase/wallet-sdk"
import WalletConnectProvider from "@walletconnect/web3-provider"

const APP_NAME = "Jellyz"
const APP_LOGO_URL = "https://jellyzapp.polyarix.space/favicon/favicon.ico"
const INFURA_ID = process.env.VUE_APP_INFURA_ID
const INFURA_RPC_URL = `https://rinkeby.infura.io/v3/${INFURA_ID}`
const DEFAULT_CHAIN_ID = 4
// const DEFAULT_CHAIN_ID = 1 // for infura mainnet

// Coinbase Wallet Provider
export const getCoinbaseWalletProvider = () => {
  const coinbaseWallet = new CoinbaseWalletSDK({
    appName: APP_NAME,
    appLogoUrl: APP_LOGO_URL,
    darkMode: false,
    overrideIsMetaMask: false,
  })
  return coinbaseWallet.makeWeb3Provider(INFURA_RPC_URL, DEFAULT_CHAIN_ID)
}

// MetaMask Provider
export const getMetaMaskProvider = () => {
  // We will prefer a provider where the property `isMetaMask` is set to true
  return (
    window.ethereum?.providers?.find((p) => !!p.isMetaMask) || (window.ethereum?.isMetaMask ? window.ethereum: null)
  )
}

// WalletConnect Provider
export const getWalletConnectProvider = () => {
  return new WalletConnectProvider({
    infuraId: INFURA_ID,
  })
}