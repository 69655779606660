<template>
    <svg class="icon" :class="[`icon-` + icon]">
        <use :xlink:href="require('@/assets/images/svg/sprite-inline.svg') + '#' + icon"></use>
    </svg>
</template>

<script >
export default {
    name: 'SvgIcon',
    props: {
        icon: {
            type: String,
            required: true
        },
    }
}
</script>

<style lang="scss">
.icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>