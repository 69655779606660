<!-- v-bind="$attrs" below is to avoid Vue warn non-props attributes -->
<template>
    <div class="page-wrapper" v-bind="$attrs">
        <PageHeader />
        <main class="main">
            <div class="page-body">
                <div class="container">
                    <div class="page-inner">
                        <ButtonBack />
                        <div class="token--info">
                            <div class="token--card">
                                <img class="token--img" :src=token.imageUri alt="token">
                                <div class="token--tag"
                                    :class="[{ 'token-card--tag-green': token.rarity == 0, 'token-card--tag-blue': token.rarity == 1, 'token-card--tag-orange': token.rarity == 2 }]">
                                    {{ token.rarity == 0 ? "Common" : token.rarity == 1 ? "Rare" : "Epic" }}</div>
                            </div>
                            <div class="token--desc">
                                <div class="token--desc-row">
                                    <span class="token--desc-text">#{{ token.id }}</span>
                                </div>
                                <div class="token--staked staked" v-if="token.staked">Staked</div>
                                <div class="token--desc-row">
                                    <span class="token--desc-text">Stage:</span>
                                    <span class="token--desc-text">{{ token.stage == 1 ? "Baby" : token.stage == 2 ?
                                            "Adult" : token.stage == 3 ? "Super" : ""
                                    }}</span>
                                </div>
                                <div class="token--desc-row">
                                    <span class="token--desc-text">Breed:</span>
                                    <span class="token--desc-text">{{ token.breedingTorpor }}/8</span>
                                </div>
                                <div class="token--desc-row">
                                    <div class="token--level">
                                        <img class="token--level-shield" src="@/assets/images/svg/shield-transp.svg"
                                            alt="shield">
                                        <span class="token--level-value">{{ token.level }}</span>
                                    </div>
                                    <span class="token--desc-text">level</span>
                                </div>
                            </div>
                            <button v-if="isXsMobile" @click="isTraitsShowed = true" class="mob-traits--btn">
                                <img src="@/assets/images/svg/dots.svg" alt="menu">
                            </button>
                        </div>
                        <div class="token--actions">
                            <ButtonTransp @click="evolveToken(token.id)" :disabledBtn="!isCanEvolve || isEvolving">
                                <span>Evolve</span>
                                <span class="button-transp--coins">{{ evolutionPrice }}</span>
                                <img src="@/assets/images/svg/small-coin.svg" alt="coin">
                            </ButtonTransp>
                            <ButtonTransp @click="sendToBreeding(token)" :disabledBtn="!isCanBreed">
                                Breed</ButtonTransp>
                            <ButtonTransp v-if="token.staked" @click="unStakeTokenHandler(token.id)">Unstake
                            </ButtonTransp>
                            <ButtonTransp v-else @click="stakeTokenHandler(token.id)"
                                :disabledBtn="!isCanStake || isStaking">Stake
                            </ButtonTransp>
                            <ButtonBase @click="levelUpToken(token.id)" :disabledBtn="!isCanLevelUp || isLevelUpping">
                                <span>{{ levelupPrice }}</span>
                                <img class="button-base--img" src="@/assets/images/svg/small-coin.svg" alt="coin">
                                <span>to level up</span>
                            </ButtonBase>
                            <p v-if="token.breedingCooldown > 0" class="token--minting-info">
                                token is <span @click="showModal" class="token--minting-info-highlight">in
                                    cooldown</span> now
                            </p>
                        </div>
                        <div v-if="!isXsMobile" class="token--traits traits">
                            <h1 class="title-h1">Traits</h1>
                            <div class="traits--table">
                                <div v-for="(item, index) in token.attributes" :key="index" class="traits--row">
                                    <span>{{ item.trait_type }}</span>
                                    <span>{{ computeTextType(item.trait_type, item.value) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <transition name="mob-traits-fade">
            <div v-if="isTraitsShowed && isXsMobile" @click="closeTraits" class="mob-traits--popup">
                <div @click.stop class="mob-traits--wrap">
                    <div class="mob-traits--bg"></div>
                    <div class="mob-traits--inner">
                        <div class="mob-traits--top">
                            <button @click="closeTraits" class="mob-traits--close">
                                <img src="@/assets/images/svg/close.svg" alt="close">
                            </button>
                        </div>
                        <div class="traits--table">
                            <div v-for="(item, index) in token.attributes" :key="index" class="traits--row">
                                <span>{{ item.trait_type }}</span>
                                <span>{{ computeTextType(item.trait_type, item.value) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <Modal v-model="isShowModal" :close="closeModal">
        <div class="modal">
            <div class="modal--bg"></div>
            <div class="modal--inner">
                <button @click="closeModal" class="modal--close">
                    <img src="@/assets/images/svg/close.svg" alt="close">
                </button>
                <p class="modal--message title-h1">Skip the cooldown?</p>
                <div class="modal--actions">
                    <ButtonTransp @click="closeModal">Cancel</ButtonTransp>
                    <ButtonBase @click="resetTokenBreedingCooldown(token.id)" :disabledBtn="!isCanBreedingCooldown">
                        <span>Skip</span>
                        <span class="button-base--coins">{{ breedingCooldownResetPrice }}</span>
                        <img src="@/assets/images/svg/small-coin.svg" alt="coin" />
                    </ButtonBase>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import router from '@/router'
import NProgress from 'nprogress'

export default {
    // 'token' props is received from beforeEnter route guard
    props: ['id'],
    data() {
        return {
            isXsMobile: false,
            isTraitsShowed: false,
            isEvolving: false,
            isLevelUpping: false,
            isShowModal: false,
            isStaking: false,
            isUnStaking: false,
            isBreedingCooldown: false,
        };
    },
    beforeUnMount() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        ...mapGetters(['getTokenById']),
        ...mapState(['balance', 'breedingCooldownResetPrice', 'evolutionPrice', 'levelupPrice', 'token', 'tokensToBreed']),
        isCanBreed() {
            if (this.isMeetBreedConditions && this.isFreePlaceToBreed && !this.isInTokensToBreed) {
                return true
            }
            return false
        },
        isCanBreedingCooldown() {
            if (this.token.breedingCooldown > 0 && !this.isBreedingCooldown && this.balance >= this.breedingCooldownResetPrice) {
                return true
            }
            return false
        },
        isCanEvolve() {
            if (this.token.level > 3 && this.token.stage < 3 && this.balance >= this.evolutionPrice) {
                return true
            }
            return false
        },
        isCanLevelUp() {
            if (this.token.level < 10 && this.token.stage < 3 && this.balance >= this.levelupPrice) {
                return true
            }
            return false
        },
        isCanStake() {
            if (this.token.stage == 3) {
                return true
            }
            return false
        },
        isMeetBreedConditions() {
            if (this.token.stage == 2 && this.token.breedingCooldown == 0) {
                return true
            }
            return false
        },
        isFreePlaceToBreed() {
            if (this.tokensToBreed[0] == null || this.tokensToBreed[1] == null) {
                return true
            }
            return false
        },
        isInTokensToBreed() {
            for (let i = 0; i < this.tokensToBreed.length; i++) {
                if (this.tokensToBreed[i] !== null && this.tokensToBreed[i].id == this.token.id) {
                    return true
                }
            }
            return false
        },
        routeId() {
            return parseInt(this.id);
        },
    },
    created() {
        this.onResize();
        window.addEventListener("resize", this.onResize);
    },
    methods: {
        ...mapActions(['evolving', 'getToken', 'levelUpping', 'loadBalance', 'loadToken', 'loadTokens', 'resetBreedingCooldown', 'stakeToken', 'unStakeToken']),
        ...mapMutations(['ADD_TOKEN_TO_BREED']),
        closeModal() {
            this.isShowModal = false;
        },
        closeTraits() {
            this.isTraitsShowed = !this.isTraitsShowed;
        },
        computeTextType(type, value) {
            if (type == "Stage") {
                if (value == 1) {
                    return "Baby"
                } else if (value == 2) {
                    return "Adult"
                } else if (value == 3) {
                    return "Super"
                }
            } else if (type == "Rarity") {
                if (value == 0) {
                    return "Common"
                } else if (value == 1) {
                    return "Rare"
                } else if (value == 2) {
                    return "Epic"
                }
            } else if (type == "Stacked") {
                if (value) {
                    return "Stacked"
                } else {
                    return "Unstacked"
                }
            }
            return value
        },
        evolveToken(id) {
            if (this.isCanEvolve) {
                NProgress.start()

                console.log('evolving token with token id', id);
                this.isEvolving = true

                this.evolving(id).then(() => {
                    this.updateData(id)
                }).finally(() => {
                    this.isEvolving = false
                    NProgress.done()
                })

            }
        },
        levelUpToken(id) {
            if (this.isCanLevelUp) {
                NProgress.start()

                console.log('LevelUpping token with token id', id);
                this.isLevelUpping = true

                console.log('isLevelUpping value in start = ', this.isLevelUpping);

                this.levelUpping(id)
                    .then(() => {
                        this.updateData(id)
                    })
                    .catch(error => console.error('levelUpToken error' + '\n', error))
                    .finally(() => {
                        console.log('finally working');
                        this.isLevelUpping = false
                        console.log('isLevelUpping value in finally = ', this.isLevelUpping);
                        NProgress.done()
                    })
            }
        },
        onResize() {
            if (window.matchMedia("(max-width: 549px)").matches) {
                this.isXsMobile = true;
            }
            else {
                this.isXsMobile = false;
            }
        },
        resetTokenBreedingCooldown(id) {
            if (this.isCanBreedingCooldown) {
                NProgress.start()

                this.isBreedingCooldown = true
                this.isShowModal = false

                this.resetBreedingCooldown(id).then(() => {
                    this.updateData(id)
                }).finally(() => {
                    this.isBreedingCooldown = false
                    NProgress.done()
                })
            }
        },
        stakeTokenHandler(id) {
            if (this.isCanStake) {
                NProgress.start()

                this.isStaking = true

                this.stakeToken(id).then(() => {
                    this.updateData(id)
                }).finally(() => {
                    this.isStaking = false
                    NProgress.done()
                })
            }
        },
        sendToBreeding(token) {
            if (this.isCanBreed) {
                for (let i = 0; i < this.tokensToBreed.length; i++) {
                    if (this.tokensToBreed[i] === null) {
                        this.ADD_TOKEN_TO_BREED({ i, token })
                        router.push({ name: 'breed' })
                        return
                    }
                }
            }
        },
        showModal() {
            this.isShowModal = true;
        },
        async updateData(id) {
            id = parseInt(id)
            await this.loadTokens()
            await this.loadBalance()
            await this.getToken(id)
        },
        unStakeTokenHandler(id) {
            NProgress.start()

            this.isUnStaking = true

            this.unStakeToken(id).then(() => {
                this.updateData(id)
            }).finally(() => {
                this.isUnStaking = false
                NProgress.done()
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.token {
    &--info {
        display: flex;
        column-gap: 1.34em;
        position: relative;
    }

    &--card {
        width: 270px;
        height: 270px;
        @include center;
        position: relative;
        flex-shrink: 0;
    }

    &--img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 6px;
        object-fit: cover
    }

    &--tag {
        @include center;
        min-width: 110px;
        height: 40px;
        padding: 7px;
        font-size: 0.9444rem;
        line-height: 1.45;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        border-radius: 2px;

        &-green {
            background-color: $green;
        }

        &-blue {
            background-color: $blue1;
        }

        &-orange {
            background-color: $orange;
        }
    }

    &--desc {
        display: flex;
        flex-direction: column;
        row-gap: 1.12em;
    }

    &--desc-row {
        display: flex;
        align-items: center;
        column-gap: 0.62em;
    }

    &--desc-text {
        font-size: 0.9444rem;
        line-height: 1.45;

        &:nth-child(1) {
            color: $grey;
        }
    }

    &--staked {
        @include center;
        align-self: flex-start;
        padding: 6px 17px;
        border-radius: 2px;
        font-size: 0.9444rem;
        line-height: 1.45;

        &.staked {
            background-color: $red;
        }
    }

    &--level {
        @include center;
        position: relative;
    }

    &--level-value {
        font-size: 0.78rem;
        line-height: 1.45;
        position: absolute;
    }

    &--actions {
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.34em;

        .button-transp,
        .button-base {
            max-width: 270px;
        }

        .button-base {
            &--img {
                width: 16px;
                margin-left: 4px;
                margin-right: 14px;
            }

            &-coins {
                margin: 0 4px 0 14px;
            }
        }

        .button-transp {
            &--coins {
                margin: 0 4px 0 14px;
            }
        }
    }

    &--minting-info-highlight {
        color: $purple-light;
        cursor: pointer;
    }

    &--traits {
        margin-top: 32px;
    }

}

.title-h1 {
    margin-bottom: 24px;
}

.traits {
    &--table {
        max-width: 565px;
        display: flex;
        gap: 0.89rem 1.34rem;
        font-size: 0.9444rem;
        line-height: 1.45;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    &--row {
        display: flex;
        justify-content: space-between;
        column-gap: 2em;

        span {
            &:nth-child(1) {
                flex-shrink: 0;
            }

            &:nth-child(2) {
                word-break: break-word;
            }
        }
    }
}

// styles for traits transition
.mob-traits {

    &-fade-enter-active,
    &-fade-leave-active {
        transition: all 0.4s ease;
    }

    &-fade-enter-from,
    &-fade-leave-to {
        opacity: 0;
    }

    &--btn {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &--popup {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        padding: $sm-p $sm-p 1rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        z-index: 10;
    }

    &--bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 30px solid $black;
        border-image: url(~@/assets/images/mob-menu-bg.png) 30;
        background-color: $black;
    }

    &--wrap {
        position: relative;
        margin-top: auto;
    }

    &--inner {
        position: relative;
        padding: 1rem 1rem 50px;

        .traits {
            &--table {
                display: grid;
                grid-template-columns: 1fr;
                row-gap: 4px;
                font-size: 0.78rem;
            }
        }
    }

    &--top {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
    }

    &--close {
        @include center;
    }
}

.modal {
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 650px;
    min-height: 370px;
    position: relative;

    &--bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 30px solid $black;
        border-image: url(~@/assets/images/mob-menu-bg.png) 30;
        background-color: $black;
    }

    &--inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        padding: 2.5rem;
    }

    &--close {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    .title-h1 {
        margin-bottom: 40px;
    }

    &--actions {
        display: flex;
        justify-content: center;
        column-gap: 32px;
        width: 100%;

        .button-transp,
        .button-base {
            width: 100%;
            max-width: 200px;
        }
    }

    .button-base--coins {
        margin: 0 4px 0 14px;
    }
}


// media queries
@include w($sm) {

    .token {

        &--actions {

            .button-transp,
            .button-base {
                max-width: none;
            }
        }
    }

    .modal {
        min-height: 290px;

        &--inner {
            padding: 2.78rem 0.83rem;
        }

        &--actions {
            column-gap: 10px;
        }

        .button-base--coins {
            margin-left: 6px;
        }
    }
}

@include w(550) {
    .token {
        &--card {
            width: 140px;
            height: 140px;
        }

        &--img {
            border-radius: 4px;
        }

        &--tag {
            font-size: 0.78rem;
            min-width: 70px;
            height: 28px;
        }

        &--desc {
            row-gap: 0.33em;
        }

        &--desc-text {
            font-size: 0.78rem;
        }

        &--staked {
            font-size: 0.78rem;
            padding: 5px 10px;
        }

        &--level-shield {
            width: 33px;
        }

        &--level-value {
            font-size: 0.67rem;
            letter-spacing: 0.01em;
        }
    }
}
</style>