import { createStore } from "vuex"
import router from "@/router"
import { JellyzService } from "@/jellyzDappLib/jellyzservice"
import Web3 from "web3"
import { JellyzObj, providerOnAccountChange, providerJellyz } from "@/services/createJellyzInstance"
// import WalletConnectProvider from "@walletconnect/web3-provider"

import {
  getCoinbaseWalletProvider,
  getMetaMaskProvider,
  getWalletConnectProvider,
} from "@/services/providers"

const ADDRESS_NAME = "jellyzAccountAddress"
const PROVIDER_TYPE = "providerType"
const COINS_DIVIDER = 1e18

// TODO use appropriate web3 provider based on what user logged in with
// let providerType = localStorage.getItem(PROVIDER_TYPE)
let provider = providerJellyz
let web3
let JellyzInstance = JellyzObj

// console.log("providerType is - ", providerType)

// if (providerType == "metamask") {
//   provider = getMetaMaskProvider()
//   provider.on("accountsChanged", providerOnAccountChange)

//   console.log("provider set 'accountsChanged' eventListener")
//   console.log("provider is - ", provider)
// } else if (providerType == "coinbasewallet") {
//   provider = getCoinbaseWalletProvider()
//   provider.on("accountsChanged", providerOnAccountChange)

//   console.log("provider set 'accountsChanged' eventListener")
//   console.log("provider is - ", provider)
// } else if (providerType == "walletconnect") {
//   ;(async () => {
//     provider = getWalletConnectProvider()
//     await provider.enable()
//     provider.on("accountsChanged", providerOnAccountChange)
//   })()

//   console.log("provider set 'accountsChanged' eventListener")
//   console.log("providerType is - ", providerType)
//   console.log("provider is - ", provider)
// }

// let web3 = new Web3(provider)
// console.log("web3.currentProvider is - ", web3.currentProvider)

// let JellyzInstance = new JellyzService(web3)

// async function providerOnAccountChange(accounts) {
//   if (accounts.length > 0) {
//     console.log("accountsChanged event, changing account on: ", accounts[0])
//     await store.dispatch("reloadAccountData", accounts[0])
//     router.push({ name: "gallery" })
//   }
// }

const store = createStore({
  state: {
    // account data
    accountAddress: null || localStorage.getItem(ADDRESS_NAME),
    balance: null,
    isCanMint: null,
    isLoggingIn: false,
    providerBase: "" || process.env.VUE_APP_INFURA_BASE,
    providerId: "" || process.env.VUE_APP_INFURA_ID,
    providerType: "" || localStorage.getItem(PROVIDER_TYPE),

    //
    mintingTokens: [
      {
        coins: 70000,
      },
      {
        coins: 50000,
      },
      {
        coins: 90000,
      },
      {
        coins: 20000,
      },
      {
        coins: 30000,
      },
      {
        coins: 10000,
      },
    ],

    // tokens data
    tokens: [],
    token: {},

    // cost of actions with tokens
    levelupPrice: JellyzInstance.levelupPrice() || null,
    evolutionPrice: JellyzInstance.evolutionPrice() || null,
    breedingPrice: JellyzInstance.breedingPrice() || null,
    breedingCooldownResetPrice: JellyzInstance.breedingCooldownResetPrice() || null,

    // mint parameters
    batchLimit: JellyzInstance.batchLimit() || null,
    whitelistBatchLimit: JellyzInstance.whitelistBatchLimit() || null,
    maxSupply: JellyzInstance.maxSupply() || null,
    mintWhitelistPrice: JellyzInstance.mintWhitelistPrice() || null,
    mintPrice: JellyzInstance.mintPrice() || null,

    // mint info
    mintedTokens: null,

    // breed
    tokensToBreed: [null, null],

    // error no app
    isNoHaveAppError: false,
    isNoHaveAppErrorMessage: "",
  },
  getters: {
    getTokenById: (state) => (id) => {
      return state.tokens.find((token) => token.id == id)
    },
    isLoggedIn: (state) => !!state.accountAddress,
    providerUrl(state) {
      return state.providerBase + state.providerId
    },
    stakedTokens(state) {
      return state.tokens.filter((token) => token.staked)
    },
    unStakedTokens(state) {
      return state.tokens.filter((token) => !token.staked)
    },
  },
  mutations: {
    // account
    LOGIN(state, address) {
      state.accountAddress = address
    },
    LOGOUT(state) {
      state.accountAddress = null
      state.balance = null
      state.isCanMint = null
      state.providerType = null
      state.tokens = []
      state.token = {}
      state.tokensToBreed = [null, null]
    },
    SET_ACCOUNT_ADDRESS(state, address) {
      state.accountAddress = address
    },
    SET_BALANCE(state, balance) {
      state.balance = balance
    },
    SET_IS_CAN_MINT(state, boolean) {
      state.isCanMint = boolean
    },
    SET_LOGGING_IN(state, value) {
      state.isLoggingIn = value
    },
    SET_PROVIDER_TYPE(state, value) {
      state.providerType = value
    },

    // tokens
    SET_TOKENS(state, tokens) {
      state.tokens = tokens
    },
    SET_TOKEN(state, token) {
      state.token = token
    },

    // breed
    ADD_TOKEN_TO_BREED(state, payload) {
      state.tokensToBreed.splice(payload.i, 1, payload.token)
    },
    REMOVE_TOKEN_FROM_BREED(state, tokenPos) {
      state.tokensToBreed.splice(tokenPos, 1, null)
    },

    // mint
    SET_MINTED_TOKENS(state, value) {
      state.mintedTokens = value
    },

    // error no app
    SET_IS_NO_HAVE_APP_ERROR(state, value) {
      state.isNoHaveAppError = value
    },
    SET_IS_NO_HAVE_APP_ERROR_MESSAGE(state, value) {
      state.isNoHaveAppErrorMessage = value
    },
  },
  actions: {
    // app authentication
    appLogin({ commit, state }, payload) {
      commit("LOGIN", payload.account)
      commit("SET_PROVIDER_TYPE", payload.providerType)
      localStorage.setItem(ADDRESS_NAME, payload.account)
      localStorage.setItem(PROVIDER_TYPE, state.providerType)
    },
    async appLogout({ commit, state }) {
      // disconnect Wallet Connect
      if (state.providerType == "walletconnect") {
        console.log("appLogout - disconnecting wallet connect")
        await provider.disconnect()
      }
      // remove provider event listeners
      provider.removeListener("accountsChanged", providerOnAccountChange)
      console.log("provider removed 'accountsChanged' eventListener")

      commit("LOGOUT")

      localStorage.removeItem(ADDRESS_NAME)
      localStorage.removeItem(PROVIDER_TYPE)

      router.push({ name: "login" })
    },
    async metamaskConnect({ commit, dispatch, state, getters }) {
      commit("SET_LOGGING_IN", true)

      try {
        if (getMetaMaskProvider()) {
          provider = getMetaMaskProvider()

          // alert("MetaMask is installed!")
          console.log("MetaMask is installed!")
          console.log("MetaMask provider", provider)

          // changing JellyzInstance
          web3 = new Web3(provider)
          JellyzInstance = new JellyzService(web3)
          console.log("current web3 provider", web3.currentProvider)

          const accounts = await provider.request({
            method: "eth_requestAccounts",
          })
          const account = accounts[0]

          if (account) {
            dispatch("appLogin", { account, providerType: "metamask" })
          }

          // event listener for changing account
          provider.on("accountsChanged", providerOnAccountChange)
          console.log("provider set 'accountsChanged' eventListener")

          // provider.on("accountsChanged", async (accounts) => {
          //   if (accounts.length > 0) {
          //     console.log(
          //       "accountsChanged event, changing account on: ",
          //       accounts[0]
          //     )
          //     await dispatch('reloadAccountData', accounts[0])
          //     router.push({ name: "gallery" })
          //   }
          // })

          console.log("accountAddress ", state.accountAddress)

          // routing to gallery after successful authorization
          if (getters.isLoggedIn) {
            router.push({ name: "gallery" })
          }
        } else {
          // alert("Please install the MetaMask!")
          commit("SET_IS_NO_HAVE_APP_ERROR", true)
          commit(
            "SET_IS_NO_HAVE_APP_ERROR_MESSAGE",
            "Please install the MetaMask!"
          )
          return
        }
      } catch (error) {
        // alert("metamaskConnect error: " + error.message + '. Filename: ' + error.filename)
        console.log("metamaskConnect error: ", error)
      } finally {
        commit("SET_LOGGING_IN", false)
      }
    },
    async walletConnect({ commit, dispatch, state, getters }) {
      commit("SET_LOGGING_IN", true)

      try {
        //  Create WalletConnect Provider
        provider = getWalletConnectProvider()

        //  Enable session (triggers QR Code modal)
        await provider.enable()

        // changing JellyzInstance
        web3 = new Web3(provider)
        JellyzInstance = new JellyzService(web3)
        console.log("current web3 provider", web3.currentProvider)

        // console.log("Wallet Connect provider", web3.eth.currentProvider)

        const accounts = await web3.eth.getAccounts()
        const account = accounts[0]

        if (account) {
          dispatch("appLogin", { account, providerType: "walletconnect" })
        }

        console.log("accountAddress ", state.accountAddress)

        // event listener for changing account
        provider.on("accountsChanged", providerOnAccountChange)
        console.log("provider set 'accountsChanged' eventListener")
        // provider.on("accountsChanged", (accounts) => {
        //   if (accounts.length > 0) {
        //     console.log(
        //       "accountsChanged event, changing account on: ",
        //       accounts[0]
        //     )
        //     commit("SET_ACCOUNT_ADDRESS", accounts[0])
        //     router.push({ name: "gallery" })
        //   }
        // })

        // routing to gallery after successful authorization
        if (getters.isLoggedIn) {
          router.push({ name: "gallery" })
        }
      } catch (error) {
        console.log("walletConnect error: ", error)
        // alert("walletConnect error: " + error.message + '. Filename: ' + error.filename)
        if (provider) {
          await provider.disconnect()
        }
      } finally {
        commit("SET_LOGGING_IN", false)
      }
    },
    async coinBaseWalletConnect({ commit, dispatch, state, getters }) {
      commit("SET_LOGGING_IN", true)

      try {
        if (getCoinbaseWalletProvider()) {
          provider = getCoinbaseWalletProvider()

          console.log("Coinbase WalletConnect is installed!")
          console.log("coinBaseWalletConnect provider", provider)

          // changing JellyzInstance
          web3 = new Web3(provider)
          JellyzInstance = new JellyzService(web3)
          console.log("current web3 provider", web3.currentProvider)

          const accounts = await provider.request({
            method: "eth_requestAccounts",
          })
          const account = accounts[0]

          if (account) {
            dispatch("appLogin", { account, providerType: "coinbasewallet" })
          }

          console.log("accountAddress ", state.accountAddress)

          // event listener for changing account
          provider.on("accountsChanged", providerOnAccountChange)
          console.log("provider set 'accountsChanged' eventListener")

          // routing to gallery after successful authorization
          if (getters.isLoggedIn) {
            router.push({ name: "gallery" })
          }
        } else {
          // alert("Please install the Coinbase Wallet!")
          commit("SET_IS_NO_HAVE_APP_ERROR", true)
          commit(
            "SET_IS_NO_HAVE_APP_ERROR_MESSAGE",
            "Please install the Coinbase Wallet!"
          )
        }
      } catch (error) {
        // alert("coinBaseWalletConnect error: " + error.message + '. Filename: ' + error.filename)
        console.log("coinBaseWalletConnect error: ", error)
      } finally {
        commit("SET_LOGGING_IN", false)
      }
    },

    // account info
    async getAccount(context) {
      let account = await JellyzInstance.resolveAccount()
      console.log("state actions getAccount displays account = ", account)
    },
    loadBalance({ commit, state }) {
      console.log("loadCoins coins loading")
      return JellyzInstance.getJELLAmount(state.accountAddress).then(
        (balance) => {
          balance = Math.floor(Number(balance))
          if (balance > 0) {
            balance = Math.floor(Number(balance) / COINS_DIVIDER)
          }
          commit("SET_BALANCE", balance)
        }
      )
    },
    async reloadAccountData({ commit, dispatch }, account) {
      commit("REMOVE_TOKEN_FROM_BREED", 0)
      commit("REMOVE_TOKEN_FROM_BREED", 1)
      commit("SET_ACCOUNT_ADDRESS", account)
      localStorage.setItem(ADDRESS_NAME, account)
      // await dispatch('loadBalance')
      await dispatch("loadTokens")
      await dispatch("checkMintAvailability")
    },

    // mint
    async checkMintAvailability({ commit, state }) {
      console.log("checkMintAvailability is loading isCanMint")
      try {
        let value = await JellyzInstance.canMint(state.accountAddress)
        commit("SET_IS_CAN_MINT", value)
      } catch (error) {
        console.log("cannot mint")
      }
    },
    async getMintAvailability({ state, dispatch }) {
      let isCanMint = state.isCanMint

      if (isCanMint == null) {
        console.log("getMintAvailability is loading isCanMint")
        await dispatch("checkMintAvailability")
      }
    },
    async getMintedAmount({ commit }) {
      console.log("getting minted tokens amount")
      let amount = await JellyzInstance.mintedCount()
      commit("SET_MINTED_TOKENS", amount)
    },
    mintTokens(context, amount) {
      console.log("start minting tokens")
      return JellyzInstance.mint(amount)
    },

    // token actions
    async getToken({ commit, dispatch, getters }, id) {
      id = parseInt(id)
      let token = getters.getTokenById(id)
      if (token) {
        commit("SET_TOKEN", token)
      } else {
        dispatch("loadToken", id)
      }
    },
    async getTokens({ state, dispatch }) {
      let tokens = state.tokens
      if (tokens.length < 1) {
        await dispatch("loadTokens")
      }
    },
    loadToken({ commit }, id) {
      console.log("token with " + id + " id is loading")
      return JellyzInstance.getJellyzToken(id).then((response) =>
        commit("SET_TOKEN", response)
      )
    },
    async loadTokens({ state, commit }) {
      let tokens = await JellyzInstance.getJellyzTokens(
        state.accountAddress,
        true
      )
      commit("SET_TOKENS", tokens)
    },
    async stakeToken({ dispatch }, id) {
      console.log("start token staking")
      id = parseInt(id)
      let tokenIdsArr = []
      tokenIdsArr.push(id)
      if (tokenIdsArr.length > 0) {
        await JellyzInstance.stake(tokenIdsArr)
      }
      console.log("finish token staking")
    },
    async unStakeToken({ dispatch }, id) {
      console.log("start token unstaking")
      id = parseInt(id)
      let tokenIdsArr = []
      tokenIdsArr.push(id)
      if (tokenIdsArr.length > 0) {
        await JellyzInstance.unstake(tokenIdsArr)
      }
      console.log("finish token unstaking")
    },
    async stakeTokens({ dispatch }, tokenIdsArr) {
      if (tokenIdsArr.length > 0) {
        await JellyzInstance.stake(tokenIdsArr)
        await dispatch("loadTokens")
      }
    },
    async unStakeTokens({ dispatch }, tokenIdsArr) {
      if (tokenIdsArr.length > 0) {
        await JellyzInstance.unstake(tokenIdsArr)
        await dispatch("loadTokens")
      }
    },
    getStakedTokens({ state }) {
      let tokens = state.tokens
      console.log("stakedTokens", tokens)
    },
    evolving(context, id) {
      console.log("start evolving")
      id = parseInt(id)
      return JellyzInstance.evolve(id)
    },
    levelUpping(context, id) {
      console.log("start levelupping")
      id = parseInt(id)
      return JellyzInstance.levelup(id)
    },
    async breedTokens(context, idsArr) {
      const [token1Id, token2Id] = idsArr
      await JellyzInstance.breed(token1Id, token2Id)
    },
    async resetBreedingCooldown({ dispatch }, id) {
      console.log("start token BreedingCooldown")
      id = parseInt(id)
      await JellyzInstance.resetBreedingCooldown(id)
      await dispatch("loadTokens")
      await dispatch("getToken", id)
      console.log("finish token BreedingCooldown")
    },
  },
  modules: {},
})

export default store
