<template>
    <div class="page-wrapper">
        <PageHeader />
        <main class="main">
            <div class="page-body">
                <div class="container">
                    <div class="page-inner">
                        <ButtonBack />
                        <div class="stacking">
                            <div class="stacking--tabs">
                                <div @click="switchTabs(item.component)" class="stacking--tab-item"
                                    v-for="(item, index) in tabs" :key="index"
                                    :class="{ active: currentComponent == item.component }">
                                    {{ item.title }}
                                </div>
                            </div>
                            <component :is="currentComponent" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UnStackedTokens from "../components/UnStackedTokens";
import StackedTokens from "../components/StackedTokens";

import NProgress from 'nprogress'

export default {
    components: { UnStackedTokens, StackedTokens },
    data() {
        return {
            tabs: [
                {
                    title: 'unstacked',
                    component: 'UnStackedTokens'
                },
                {
                    title: 'stacked',
                    component: 'StackedTokens'
                },
            ],
            currentComponent: 'UnStackedTokens',
        }
    },
    computed: {
        ...mapState(['tokens']),
    },
    created() {
        this.loadTokens()
    },
    methods: {
        ...mapActions(['getTokens']),
        switchTabs(component) {
            this.currentComponent = component;
        },
        loadTokens() {
            NProgress.start()
            this.getTokens().catch((error) => console.log("stacking loadTokens error ", error)).finally(() => NProgress.done())
        }
    }
}
</script>

<style lang="scss" scoped>
.stacking {
    &--tabs {
        display: flex;
        border-radius: 2px;
        overflow: hidden;
        margin-bottom: 1.78em;
    }

    &--tab-item {
        width: 100%;
        max-width: 16.5em;
        font-size: 0.9444rem;
        line-height: 1.45;
        cursor: pointer;
        border: 2px solid $purple;
        padding: 0.88em;
        text-align: center;
        transition: $anim-03;

        // @include offHoverOnDevices {
        //     &:hover {
        //         background-color: $purple;
        //     }
        // }

        &.active {
            background-color: $purple;
        }
    }
}

// media queries
@include w($sm) {
    .stacking {
        &--tabs {
            margin-bottom: 1.11em;
        }
    }
}

@include w(550) {
    .stacking {
        &--tab-item {
            font-size: 1rem;
            line-height: 1.25;
            padding: 0.5em;
        }
    }
}
</style>