<template>
    <button class="login-button" :class="[{disabled: disabledBtn}, className]" :disabled="disabledBtn">
        <img :src="imgSrc" alt="wallet logo">
        <span>
            <slot></slot>
        </span>
    </button>
</template>

<script>
export default {
    props: ['imgSrc', 'className', 'disabledBtn'],
    name: 'LoginButton',
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.33rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 0.222em;
    background-color: $white;
    color: $black;
    padding: 0.5em 1em;
    width: 100%;
    max-width: 16.11rem;
    cursor: pointer;
    transition: $anim;

    @include offHoverOnDevices {
        &:hover {
            background-color: $purple;

            span {
                color: $white;
            }
        }
    }
    &.disabled{
        background-color: $grey-dark;
        pointer-events: none;
        cursor: default;
    }
}

span {
    margin-left: 0.33em;
    transition: $anim;
}

// media queries
@include w($tablet) {
    .login-button {
        min-height: 3.11rem;
        font-size: 0.78rem;
        line-height: 1.45;
    }
}
</style>