<template>
    <header class="header">
        <div class="container">
            <div class="header-inner">
                <router-link v-if="!isMobile" :to="{ name: 'screens' }" class="header-logo">
                    <img src="@/assets/images/svg/logo.svg" alt="logo">
                </router-link>
                <nav v-if="!isMobile" class="header-nav">
                    <ul>
                        <li>
                            <a href="#" class="header-nav-link">Whitepaper</a>
                        </li>
                        <li>
                            <a href="#" class="header-nav-link">History</a>
                        </li>
                        <li>
                            <a href="#" class="header-nav-link">
                                <SvgIcon icon="discord" />
                            </a>
                        </li>
                        <li>
                            <a href="#" class="header-nav-link">
                                <SvgIcon icon="opensea" />
                            </a>
                        </li>
                    </ul>
                </nav>
                <div v-if="!isMobile" class="header-actions">
                    <router-link :to="{ name: 'stacking' }" class="header-link">Stake</router-link>
                    <router-link :to="{ name: 'breed' }" class="header-link">Breed</router-link>
                </div>
                <div class="header-user-block">
                    <div class="header-coins">
                        <img src="@/assets/images/svg/coin.svg" alt="coins">
                        <span class="coins-value">{{ balance || 0 }}</span>
                    </div>
                    <button @click="appLogout" v-if="!isMobile" class="user-logout-btn">
                        <SvgIcon icon="logout" />
                        <span>Logout</span>
                    </button>
                </div>
                <button v-if="isMobile" @click="isMobMenuShowed = true" class="mobile-menu-btn">
                    <img src="@/assets/images/svg/menu.svg" alt="menu burger">
                </button>

                <!-- mobile menu -->
                <transition name="mob-menu-fade">
                    <div v-if="isMobile && isMobMenuShowed" class="mobile-menu-wrap" @click="closeMenu">
                        <div class="mobile-menu" @click.stop>
                            <div class="mobile-menu-bg"></div>
                            <div class="mobile-menu-inner">
                                <div class="mobile-menu-top">
                                    <button @click="closeMenu" class="mobile-menu-close">
                                        <img src="@/assets/images/svg/close.svg" alt="close">
                                    </button>
                                </div>
                                <div class="mobile-menu-actions">
                                    <router-link :to="{ name: 'stacking' }" class="header-link">Stake</router-link>
                                    <router-link :to="{ name: 'breed' }" class="header-link">Breed</router-link>
                                </div>
                                <nav class="mobile-menu-nav">
                                    <ul>
                                        <li>
                                            <a href="#" class="mobile-menu-nav-link">Whitepaper</a>
                                        </li>
                                        <li>
                                            <a href="#" class="mobile-menu-nav-link">History</a>
                                        </li>
                                        <li>
                                            <a href="#" class="mobile-menu-nav-link">
                                                <SvgIcon icon="discord" />
                                                <span>Discort</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="mobile-menu-nav-link">
                                                <img class="open-sea" src="@/assets/images/svg/opensea-mob.svg"
                                                    alt="open sea">
                                                <span>Opensea</span></a>
                                        </li>
                                        <li><button @click="appLogout" class="user-logout-btn">
                                            <SvgIcon icon="logout" />
                                            <span>Logout</span>
                                            </button></li>
                                    </ul>
                                </nav>

                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'PageHeader',
    data() {
        return {
            isMobile: false,
            isMobMenuShowed: false
        }
    },
    methods: {
        ...mapActions(['appLogout']),
        onResize() {
            if (window.matchMedia("(max-width: 1199px)").matches) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        closeMenu() {
            this.isMobMenuShowed = !this.isMobMenuShowed;
        },
    },
    created() {
        this.onResize();
        window.addEventListener("resize", this.onResize);
    },
    beforeUnMount() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        ...mapState(['balance'])
    }
}
</script>

<style lang="scss">
.header {
    &-inner {
        display: flex;
        align-items: center;
        padding: 24px 0;
    }

    &-logo {
        @include center;
    }

    &-nav {
        margin-left: 40px;

        ul {
            display: flex;
            align-items: center;
            column-gap: 32px;
        }
    }

    &-nav-link {
        transition: $anim-03;

        @include offHoverOnDevices {
            &:hover {
                color: $purple;
            }
        }

    }

    &-actions {
        display: flex;
        align-items: center;
        margin-left: auto;
        column-gap: 16px;
    }

    &-link {
        @include center;
        min-width: 140px;
        padding: 0.7em;
        border: 2px solid $purple;
        border-radius: 2px;
        transition: $anim-03;

        @include offHoverOnDevices {
            &:hover {
                background-color: $purple;
            }
        }
    }

    &-user-block {
        display: flex;
        align-items: center;
        column-gap: 35px;
        margin-left: 35px;
    }

    &-coins {
        display: flex;
        align-items: center;
    }
}

.coins-value {
    margin-left: 8px;
}

.user-logout-btn {
    display: flex;
    align-items: center;
    padding: 0.3em 0;

    @include offHoverOnDevices {
        &:hover {
            color: $purple;
        }
    }

    .icon {
        transition: $anim-03;
    }

    span {
        transition: $anim-03;
        margin-left: 8px;
    }
}

.mobile-menu {
    position: relative;
    margin: auto;

    &-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 15px;
        background-color: rgba($black, 0.8);
        overflow-y: auto;
        z-index: 50;
    }

    &-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 30px solid $black;
        border-image: url(~@/assets/images/mob-menu-bg.png) 30;
        background-color: $black;
    }

    &-inner {
        padding: $sm-p $sm-p 40px;
        position: relative;
    }

    &-top {
        display: flex;
        justify-content: flex-end;
    }

    &-close {
        @include center;
    }

    &-actions {
        display: flex;
        column-gap: 10px;
        margin-top: 24px;

        .header-link {
            width: 100%;
            min-width: 125px;
            padding: 0.28em;
        }
    }

    &-nav {
        margin-top: 32px;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
        }

        .user-logout-btn .icon {
            font-size: 21px
        }
    }

    &-nav-link {
        @include center;

        .icon-discord {
            font-size: 15px;
        }

        span {
            margin-left: 8px;
        }

        .open-sea {
            width: 21px;
        }
    }
}

.menu-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($black, 0.8);
}

// styles for menu transition
.mob-menu {

    &-fade-enter-active,
    &-fade-leave-active {
        transition: all 0.4s ease;
    }

    &-fade-enter-from,
    &-fade-leave-to {
        opacity: 0;
    }
}

// media queries
@include w(1300) {
    .header-logo {
        img {
            width: 160px;
        }
    }
}

@include w($md) {
    .header {
        &-inner {
            justify-content: space-between;
        }

        &-user-block {
            margin-left: 0;
        }
    }
}

@include w($sm) {
    .header {
        &-inner {
            padding: 1em 0;
        }

        &-user-block {
            font-size: 0.78rem;
        }
    }

    .mobile-menu {
        margin: 0;
    }
}
</style>