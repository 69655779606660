// import Web3 from "web3";
// import { Contract } from "web3-eth-contract";
import { JELLABI, JellyzABI, JellyzFactoryABI } from "./jellyz-contracts-abi";
import { JELLAddress, JellyzAddress, JellyzFactoryAddress, whitelist } from "./jellyz-contracts";

import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

export class JellyzService{
    constructor(web3) {
        this.uriPrefix = 'data:application/json;base64,';
        this.batchLimit = function () { return 20; };
        this.whitelistBatchLimit = function () { return 5; };
        this.maxSupply = function () { return 10000; };
        this.mintWhitelistPrice = function () { return 0.09; }; // mint token price in ETH
        this.mintPrice = function () { return 0.11; }; // mint token price in ETH
        this.levelupPrice = function () { return 200; }; // levelup price in $JELL
        this.evolutionPrice = function () { return 400; }; // evolution price in $JELL
        this.birthPrize = function () { return 10000; }; // birth prize in $JELL
        this.breedingPrice = function () { return 600; }; // breeding price if $JELL 
        this.breedingCooldownResetPrice = function () { return 600; }; // breeding cooldown reset price in $JELL
        this.web3 = web3;
        this.jell = new this.web3.eth.Contract(JELLABI, JELLAddress);
        this.jellyz = new this.web3.eth.Contract(JellyzABI, JellyzAddress);
        this.jellyzFactory = new this.web3.eth.Contract(JellyzFactoryABI, JellyzFactoryAddress);
    }

    async resolveAccount(address) {
        if(!address) {
            address = await this.web3.eth.getAccounts();
            address = address[0];
            if(!address) {
                return Promise.reject("No selected account");
            }
        }
        return address;
    }

    async getJELLAmount(address){
        address = await this.resolveAccount(address);
        return await this.jell.methods.balanceOf(address).call();
    }

    parseJellyzToken(token, tokenId) {
        return {
            id: tokenId,
            name: token.name,
            imageUri: token.image,
            level: token.attributes.find(x => x.trait_type == "Level")?.value || 0,
            stage: token.attributes.find(x => x.trait_type == "Stage")?.value || 0,
            rarity: token.attributes.find(x => x.trait_type == "Rarity")?.value || 0,
            staked: token.attributes.find(x => x.trait_type == "Stacked")?.value || false,
            breedingTorpor: token.attributes.find(x => x.trait_type == "Breeding Torpor")?.value || 0,
            breedingCooldown: token.attributes.find(x => x.trait_type == "Breeding Cooldown")?.value || 0,
            attributes: token.attributes
        }
    }

    async getJellyzTokens(address, includeRate = false) {
        address = await this.resolveAccount(address);
        var tokensBalance = await this.jellyz.methods.balanceOf(address).call();
        var tokens = [];
        for(let i = 0; i < tokensBalance; i++) {
            var tokenId = await this.jellyz.methods.tokenOfOwnerByIndex(address, i).call();
            var tokenURI = await this.jellyz.methods.tokenURI(tokenId).call();
            var tokenInfo = Buffer.from(tokenURI.substring(this.uriPrefix.length), 'base64').toString('utf-8');
            const token = this.parseJellyzToken(JSON.parse(tokenInfo), tokenId);
            if(token.staked && includeRate) {
                token.dailyRate = await this.jellyzFactory.methods.getRate(tokenId);
            }
            tokens.push(token);
        }
        return tokens;
    }

    async getJellyzToken(tokenId) {
        var tokenURI = await this.jellyz.methods.tokenURI(tokenId).call();
        var tokenInfo = Buffer.from(tokenURI.substring(this.uriPrefix.length), 'base64').toString('utf-8');
        return this.parseJellyzToken(JSON.parse(tokenInfo), tokenId);
    }

    async mintStarted() {
        return await this.jellyz.methods.mintStarted().call();
    }
    async mintWhitelistStarted() {
        return await this.jellyz.methods.mintWhitelistStarted().call();
    }


    async mintedCount() {
        return await this.jellyz.methods.minted().call();
    }

    async inWhitelist(address) {
        address = await this.resolveAccount(address);
        return whitelist.indexOf(address) >= 0;
    }

    async canMint(address) {
        address = await this.resolveAccount(address);
        if(await this.mintedCount() >= this.maxSupply()) {
            return false;
        }
        if(await this.mintWhitelistStarted() && await this.inWhitelist(address)) {
            return true;
        }
        if(await this.mintStarted()) {
            return true;
        }
        
        return true;
    }
    
    async getMintPrice(amount){
        // TODO add actual prices per state
        return (await Promise.resolve((0.1 * amount)).toString());
    }

    async mint(amount) {
        var address = await this.resolveAccount();
        var price = this.web3.utils.toWei((this.mintPrice() * amount).toString());
        return await this.jellyz.methods.mint(amount).send( { value: price, from: address });
    }

    async mintWhitelist(amount) {
        var address = await this.resolveAccount();

        var whitelistHashes = whitelist.map(addr => keccak256(addr));
        var tree = new MerkleTree(whitelistHashes, keccak256, {sortPairs: true});
        var proof = tree.getHexProof(keccak256(address));

        var price = this.web3.utils.toWei((this.mintWhitelistPrice() * amount).toString());
        return await this.jellyz.methods.mintWhitelist(proof, amount).send( { value: price });
    }

    // breed, only stage 2 (adult) can breed
    async breed(token1Id, token2Id) {
        var address = await this.resolveAccount();
        return await this.jellyz.methods.breeding(token1Id, token2Id).send({from: address});
    }

    // breed, only stage 2 (adult) can breed
    async resetBreedingCooldown(tokenId) {
        return await this.jellyz.methods.resetBreedingCooldown(tokenId);
    }


    // levelup, only stage 1 and 2 can level up and only when level < 10
    async levelup(tokenId) {
        var address = await this.resolveAccount();
        return await this.jellyz.methods.levelup(tokenId).send({from: address});
    }

    // evolve, only stage 1 and 2 can evolve
    async evolve(tokenId) {
        var address = await this.resolveAccount();
        return await this.jellyz.methods.evolve(tokenId).send({from: address});
    }

    // stake
    async stake(tokenIds) {
        var address = await this.resolveAccount();
        return await this.jellyzFactory.methods.stake(tokenIds).send({from: address});
    }
    // unstake
    async unstake(tokenIds){
        var address = await this.resolveAccount();
        return await this.jellyzFactory.methods.claimAndUnstake(tokenIds).send({from: address});
    }
} 