<template>
    <div class="page-wrapper">
        <PageHeader />
        <main class="main">
            <div class="open-sea">
                <div class="container">
                    <div class="open-sea-inner">
                        <h1 class="title-h1">Sorry, mint is over, to get Jellyz NFT please visit secondary market</h1>
                        <a href="javascript:void(0)" class="collection-link">
                            <SvgIcon icon="opensea"/>
                            <span>See the Collection</span>
                        </a>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.open-sea {
    width: 100%;
    margin-top: 17.8vh;
    margin-bottom: 5vh;

    &-inner {
        flex-direction: column;
        @include center;
    }
}

.title-h1 {
    max-width: 12.08em;
    margin-bottom: 0.8em;
    text-align: center;
}

.collection-link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.33rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 0.222em;
    background-color: $blue;
    color: $white;
    padding: 0.5em 1em;
    width: 100%;
    max-width: 14.17rem;
    cursor: pointer;
    transition: $anim;

    @include offHoverOnDevices {
        &:hover {
            background-color: rgba($blue, 0.7);
        }
    }

    .icon-opensea{
        font-size: 24px;
    }

    span {
        margin-left: 0.45em;
        transition: $anim;
    }
}


// media queries

@include w($tablet) {}

@include w($sm) {
    .collection-link{
        max-width: 16.11rem;
        img{
            width: 31px;
        }
    }
}
</style>