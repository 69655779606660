import { createApp } from "vue"
import App from "./App.vue"
import components from "@/components/UI"
import router from "./router"
import store from "./store"
import { svgSpriteDirectivePlugin } from 'vue-svg-sprite'
import VueUniversalModal from 'vue-universal-modal'

import 'vue-universal-modal/dist/index.css'
import 'nprogress/nprogress.css'
import "@/assets/styles/style.scss"

const app = createApp(App)

components.forEach(component => {
    app.component(component.name, component)
})

app.use(VueUniversalModal, {
  teleportTarget: '#modals',
});

app.use(svgSpriteDirectivePlugin, {url: require(`@/assets/images/svg/sprite-inline.svg`)} /* options */)

app.use(store).use(router).mount("#app")
