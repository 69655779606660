<template>
    <div class="page-wrapper">
        <PageHeader />
        <main class="main">
            <div class="gallery">
                <div class="container">
                    <div class="gallery--inner">
                        <div class="block-title">
                            <h1 v-if="isTokensLoading" class="title-h1">Tokens loading...</h1>
                            <h1 v-else-if="(tokens.length > 0)" class="title-h1">Your tokens</h1>
                            <h1 v-else class="title-h1">You don't have any tokens</h1>
                            <!-- <p class="minting-tokens-info">
                                3 tokens <span class="minting-tokens-info--highlight">in minting</span> now
                            </p> -->
                            <div v-if="isCanMint" @click="goToMint" class="mint-btn-wrap">
                                <ButtonBase>Mint</ButtonBase>
                            </div>
                        </div>
                        <div v-if="(tokens.length > 0)" class="gallery--body">
                            <TokenCard v-for="(token, i) in tokens" :key="i" :tokenData=token />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TokenCard from "../components/TokenCard.vue";
import router from "@/router";

export default {
    components: { TokenCard },
    data() {
        return {
            isTokensLoading: false
        }
    },
    computed: {
        ...mapState(['isCanMint', 'tokens']),
    },
    created() {
        console.log('gallery created lifecycle hook');
        this.checkMintAvailability()
        this.loadTokens()
    },
    methods: {
        ...mapActions(['checkMintAvailability', 'getTokens']),
        goToMint() {
            router.push({ name: 'mint' })
        },
        loadTokens() {
            console.log("gallery loadTokens start")
            this.isTokensLoading = true
            this.getTokens().catch((error) => console.log("gallery loadTokens error ", error)).finally(() => this.isTokensLoading = false)
        }
    }
}
</script>

<style lang="scss" scoped>
.gallery {
    &--inner {
        padding-top: 45px;
        padding-bottom: 100px;

        &>.title-h1 {
            padding-top: 45px;
        }
    }

    &--body {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding-top: 1.78rem;
        flex-wrap: wrap;
        gap: 45px 22px;
    }
}

.block-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.minting-tokens-info {
    margin-left: auto;
    margin-right: 1.34em;

    &--highlight {
        color: $purple-light;
    }
}

.mint-btn-wrap {
    width: 100%;
    max-width: 15em;
}

// media queries
@include w(1400) {
    .gallery {
        &--body {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@include w(1130) {
    .gallery {
        &--body {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@include w($tablet) {
    .gallery {
        &--inner {
            padding-bottom: 120px;
        }
    }

    .block-title {
        flex-direction: column;
        align-items: flex-start;
    }

    .minting-tokens-info {
        margin: 0.56em 0 0;
    }

    .mint-btn-wrap {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        max-width: none;
        padding: 12px $sm-p 24px;
        background: linear-gradient(180deg, rgba(24, 24, 24, 0) 0%, rgba(24, 24, 24, 0.71) 76.04%);
    }
}

@include w(870) {
    .gallery {
        &--body {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include w($sm) {
    .gallery {
        &--inner {
            padding-top: 6px;

            &>.title-h1 {
                padding-top: 30px;
            }
        }

        &--body {
            padding-top: 0.83em;
        }
    }
}

@include w(550) {
    .gallery {
        &--body {
            gap: 22px 10px;
        }
    }
}
</style>