import { createRouter, createWebHistory } from "vue-router"
import store from "@/store"
import NProgress from "nprogress"

import LoginView from "@/views/LoginView.vue"
import ScreensView from "@/views/ScreensView.vue"
import OpenSea from "@/views/OpenSea.vue"
import GalleryView from "@/views/GalleryView"
import MintingError from "@/views/MintingError"
import NFTInfo from "@/views/NFTInfo"
import MintingTokens from "@/views/MintingTokens"
import StackingView from "@/views/StackingView"
import BreedView from "@/views/BreedView"
import MintView from "@/views/MintView"

const routes = [
  // { path: "/:pathMatch(.*)*", name: "404", component: NotFound },
  {
    path: "/",
    name: "screens",
    component: ScreensView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Jellyz Login",
      isAuth: false,
    },
  },
  {
    path: "/open-sea",
    name: "open-sea",
    component: OpenSea,
    meta: {
      title: "Jellyz Open Sea",
      isAuth: true,
    },
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
    meta: {
      title: "Jellyz Gallery",
      isAuth: true,
    },
    // beforeEnter(to, from, next) {
    //   store
    //     .dispatch("getTokens")
    //     .then(() => next())
    //     .catch((error) => console.log("beforeEnter error ", error))
    // },
  },
  {
    path: "/minting-error",
    name: "minting-error",
    component: MintingError,
    meta: {
      title: "Jellyz Minting Error",
      isAuth: true,
    },
  },
  {
    path: "/token/:id",
    name: "token",
    component: NFTInfo,
    props: true,
    meta: {
      title: "Jellyz NFT Info",
      isAuth: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("getToken", routeTo.params.id).then((token) => {
        routeTo.params.token = token
        next()
      })
    },
  },
  {
    path: "/minting-tokens",
    name: "minting-tokens",
    component: MintingTokens,
    meta: {
      title: "Jellyz Tokens in minting",
      isAuth: true,
    },
  },
  {
    path: "/stacking",
    name: "stacking",
    component: StackingView,
    meta: {
      title: "Jellyz Staking",
      isAuth: true,
    },
    // beforeEnter(to, from, next) {
    //   store.dispatch("getTokens").then(() => next())
    // },
  },
  {
    path: "/breed",
    name: "breed",
    component: BreedView,
    meta: {
      title: "Jellyz Breed",
      isAuth: true,
    },
  },
  {
    path: "/mint",
    name: "mint",
    component: MintView,
    meta: {
      title: "Jellyz Mint",
      isAuth: true,
    },
    beforeEnter(to, from, next) {
      store
        .dispatch("getMintAvailability")
        .then(() => {
          if (store.state.isCanMint) {
            next()
          } else {
            next({ name: "gallery" })
          }
        })
        .catch((error) => {
          console.log(error)
          next({ name: "gallery" })
        })
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0, left: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // page title
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "Jellyz NFT"
  }

  // progress bar
  NProgress.start()

  // check if logged in
  if (to.name !== "login" && to.meta.isAuth) {
    if (store.getters.isLoggedIn) {
      // store
      //   .dispatch("loadBalance")
      //   .then(() => {
      //     next()
      //   })
      //   .catch((error) => console.log("beforeEach error ", error))
      next()
    } else {
      next({ name: "login" })
    }
  } else if (to.name == "login" && store.getters.isLoggedIn) {
    next({ name: "gallery" })
  } else next()
})

router.afterEach(() => {
  NProgress.done()
  if (store.getters.isLoggedIn) {
    store
      .dispatch("loadBalance")
      .catch((error) => console.log("loadBalance error ", error))
  }
})

export default router
