<template>
    <div class="pages-page">
        <section>
            <div class="container">
                <h1>Screens</h1>
                <ol>
                    <li v-for="(item, i) in screens" :key="i">
                        <router-link :to="{ path: item.path }">{{ item.name }}</router-link>
                    </li>
                </ol>
            </div>
        </section>
    </div>
</template>

<script >
export default {
    data() {
        return {
            screens: [
                {
                    path: '/login',
                    name: 'Login',
                },
                {
                    path: '/open-sea',
                    name: 'Open sea',
                },
                {
                    path: '/gallery',
                    name: 'Gallery',
                },
                {
                    path: '/minting-error',
                    name: 'Minting error',
                },
                {
                    path: '/minting-tokens',
                    name: 'Minting tokens',
                },
                {
                    path: '/stacking',
                    name: 'Stacking',
                },
                {
                    path: '/breed',
                    name: 'Breed',
                },
                {
                    path: '/mint',
                    name: 'Mint',
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
body{
    background: none;
}
</style>